import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import myImg from "../../Assets/about.jpg";
import Tilt from "react-parallax-tilt";
import {
  AiFillGithub,
  AiOutlineTwitter,
  AiFillInstagram,
} from "react-icons/ai";
import { FaLinkedinIn } from "react-icons/fa";

function Home2() {
  return (
    <Container fluid className="home-about-section" id="about">
      <Container>
        <Row>
          <Col md={8} className="home-about-description">
            <h1 style={{ fontSize: "2.6em" }}>
              LET ME <span className="purple"> INTRODUCE </span> MYSELF
            </h1>
            <p className="home-about-body">
            I am passionate about coding and love spending time solving problems and debugging code. I am constantly striving to improve my skills and keep up with the latest developments in the field.
              <br />
              <br />I am fluent in classics like
              <i>
             
                <span className="purple">Javascript ,Node js,Mysql, Mongodb,Neo4j and C++  </span>
              </i>
              <br />
              <br />
              I have been working with gRPC and Protocol Buffers, and have gained valuable experience in building scalable, efficient microservices. I am excited to continue learning and growing as a developer, and I am always open to new opportunities and challenges &nbsp;
              <i>
                <b className="purple">
My goal is to create the routes and API for an application, and I enjoy developing server-side applications in Node JS </b> and
              {" "}
                <b className="purple">
                  I enjoy creating queries in mysql, mongo db, and other databases. In the case of graph databases, I have utilized the cypher query language to create applications for recommendations.
                </b>
              </i>
              <br />
              <br />
              Whenever possible, I  apply my passion for developing products
              with <b className="purple">Database like mysql,mongodb,Neo4j</b> and also with
              <i>
                <b className="purple">
                  {" "}
                  Modern Javascript Library and Frameworks
                </b>
              </i>
              &nbsp; like
              <i>
                <b className="purple"> Node js , Express js </b>
              </i>
            </p>
          </Col>
          <Col md={4} className="myAvtar">
            <Tilt>
              <img style={{borderRadius:100}} src={myImg} className="img-fluid" alt="avatar" />
            </Tilt>
          </Col>
        </Row>
        <Row>
          <Col md={12} className="home-about-social">
            <h1>FIND ME ON</h1>
            <p>
              Feel free to <span className="purple">connect </span>with me
            </p>
            <ul className="home-about-social-links">
              <li className="social-icons">
                <a
                  href="https://github.com/avhishek-49"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <AiFillGithub />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://www.linkedin.com/in/avhishek-poudel-72a43b1b2/"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <FaLinkedinIn />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://www.instagram.com/avhishek_49/?next=%2F"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour home-social-icons"
                >
                  <AiFillInstagram />
                </a>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}
export default Home2;
