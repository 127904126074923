import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";

import collegeImage from "../../Assets/Projects/college.jpeg";
import chatify from "../../Assets/Projects/chat.jpg";

import Fastbanking from "../../Assets/Projects/banking.jpg";
import walletImage from "../../Assets/Projects/wallet.jpg";
import humanityImage from "../../Assets/Projects/humanity.jpg";



function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          My Recent <strong className="purple">Works </strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are a few projects I've worked on recently.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={Fastbanking}
              isBlog={false}
              title="Fastbanking (Global Smart Plus)"
              description="In my role as a backend developer for the FastBanking project, I played a pivotal role in driving its success by implementing key features that revolutionized the digital banking experience. One of my major contributions was the development of a robust bulk customer registration system, streamlining the onboarding process for both users and financial institutions. Additionally, I spearheaded the integration of third-party API signature generation, ensuring seamless and secure communication with external platforms. Furthermore, I led the implementation of a messaging system on Viber, enhancing customer engagement and accessibility. My expertise also extended to integrating major third-party services such as Khalti, Locker, various ISPs, and insurance providers, thereby expanding the platform's functionality and reach. Through meticulous problem-solving and collaboration, I overcame challenges to deliver a high-quality, scalable solution that empowers financial institutions to offer cutting-edge digital banking services"
              demoLink="https://globalonline.gibl.com.np/"
            />
          </Col>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={humanityImage}
              isBlog={false}
              title="Balance Humanity"
              description="In my capacity as a backend and frontend developer for the Balance Humanity project, I had the privilege of contributing to a pioneering fintech initiative at the intersection of technology and humanitarianism. This project aimed to streamline and target aid distribution in disaster-prone regions, particularly in Nepal, leveraging innovative solutions to revolutionize traditional aid delivery processes. My responsibilities encompassed a broad spectrum of tasks, including developing a secure and efficient user authentication system, implementing robust KYC registration and verification processes, and creating an intuitive and visually appealing user interface using React.js. Leveraging technologies such as Node.js for scalable backend operations, MySQL for data storage, and Neo4j for fraud detection, I played a critical role in ensuring the platform's reliability, security, and performance. Additionally, my involvement in integrating imePay's API for secure interbank fund transfers within Nepal further enhanced the platform's financial transparency and reliability. Through meticulous attention to detail and a collaborative approach, I contributed to the creation of a transformative fintech platform that empowers disaster-affected individuals and communities while fostering transparency, accountability, and efficiency in aid transactions."
              demoLink="#"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={walletImage}
              isBlog={false}
              title="C-Wallet"
              description="As a backend developer on the cWallet project, I played a vital role in enhancing the omnichannel digital wallet experience, enabling fintech companies to offer diverse financial services seamlessly. My primary responsibilities included developing a streamlined customer onboarding process and implementing robust KYC verification mechanisms, ensuring compliance and security standards were met. Additionally, I spearheaded the creation of a Content Management System (CMS), empowering administrators to manage content efficiently across platforms. Furthermore, I contributed to implementing intricate wallet inner logics, optimizing the functionality and performance of the digital wallet. Through meticulous attention to detail and technical expertise, I helped deliver a comprehensive solution that enables fintechs to operate as digital money transfer operators while offering a seamless and secure user experience."
              demoLink="#"
            />
          </Col>





          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={collegeImage}
              isBlog={false}
              title="School College Management"
              description="As a web developer, I had the opportunity to work on a college management project that offers a comprehensive solution for managing academic and administrative tasks. The project, named College Management Project, is designed for colleges and universities with features such as student and teacher management, course management, and reporting. I was responsible for the development of the web application using the latest technologies such as Node.js and React.js. The project was built with a secure and robust architecture, which ensures the smooth functioning of the application. I also implemented a role-based access control system, which allows for different privileges for admin, teachers, and students. The project showcases my ability to deliver a high-quality and efficient web application that meets the needs of the education industry."
              demoLink="https://github.com/avhishek-49/college-management-project"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={chatify}
              isBlog={false}
              title="Chatify-Neo4j"
              description="In my journey as a web developer, I had the honor of contributing to the development of Chatify, a dynamic real-time chat application powered by Node.js and gRPC technology. Chatify was engineered to facilitate seamless communication, boasting features like instant messaging, group chat functionality, and secure file sharing capabilities. My primary responsibility centered around crafting the server-side logic using Node.js and gRPC, laying the foundation for robust and efficient communication channels. Leveraging the power of Socket.io, I integrated real-time functionality, ensuring that users could enjoy instantaneous interaction across devices. To bolster security, I implemented QR code authorization mechanisms, safeguarding user data and communication channels against unauthorized access. In addition, I orchestrated the implementation of Neo4j for logging and other essential functionalities, enhancing the application's scalability and efficiency. Through meticulous attention to detail and a commitment to excellence, I contributed to the creation of a high-quality real-time communication solution that addresses the diverse needs of users while prioritizing security and efficiency."

            />
          </Col>






          {/* 
        <Col md={4} className="project-card">
          <ProjectCard
            imgPath={emotion}
            isBlog={false}
            title="Face Recognition and Emotion Detection"
            description="Trained a CNN classifier using 'FER-2013 dataset' with Keras and tensorflow backened. The classifier sucessfully predicted the various types of emotions of human. And the highest accuracy obtained with the model was 60.1%.
            Then used Open-CV to detect the face in an image and then pass the face to the classifer to predict the emotion of a person."
            
          />
        </Col> */}
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
