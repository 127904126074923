import React from "react";
import Typewriter from "typewriter-effect";

function Type() {
  return (
    <Typewriter
      options={{
        strings: [
         
          "Backend Developer",
          "carpediem",
          "Cricket Enthusiast"
        ],
        autoStart: true,
        loop: true,
        deleteSpeed: 38,
      }}
    />
  );
}

export default Type;
