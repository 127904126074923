import React from "react";
import Card from "react-bootstrap/Card";
import { ImPointRight } from "react-icons/im";

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }}>
            <span className="purple"></span>
           
            <br /><span className="purple"> Abishek Paudel</span> is a software engineer and web developer with experience in <span className="purple"> Node.js</span> and
             the Express framework. He is currently pursuing a BSc in Computer Science and Information Technology at <span className="purple"> St. Lawrence College Nepal.</span> and is also working as a software engineer at a fintech company in Nepal. Abishek specializes in developing web applications with GRPC proto buff, and is committed to delivering high-quality software solutions. With his strong technical skills and passion for technology, Abishek is a valuable asset to any team. He is currently living in  <span className="purple"> Kathmandu, Nepal.</span>
            <br />
                                                                          
            <br />
            Apart from coding, some other activities that I love to do!
          </p>
          <ul>
            <li className="about-activity">
              <ImPointRight /> Playing Cricket
            </li>
            <li className="about-activity">
              <ImPointRight /> Writting Tech Blogs
            </li>
            <li className="about-activity">
              <ImPointRight /> Travelling
            </li>
            <li className="about-activity">
              <ImPointRight /> Spending Time with His Family and Friends
            </li>
            <li className="about-activity">
              <ImPointRight /> He enjoys imparting knowledge to juniors and learning from the experience of superiors
            </li>
          </ul>

          <p style={{ color: "rgb(155 126 172)" }}>
          "Good code is its own best documentation. As you’re about to add a comment, ask yourself, ‘How can I improve the code so that this comment isn’t needed?’" {" "}
          </p>
          <footer className="blockquote-footer">Abishek</footer>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
